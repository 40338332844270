import { CONNECT_TO_EXTERNAL_API } from '@m/constants/FeatureFlags';
import { setDefaultAuthMethod } from '@m/utils/http';

import Providers from 'providers';

import Pay from './Pay';
import { GlobalStyles } from './Pay.styled';

if (CONNECT_TO_EXTERNAL_API) setDefaultAuthMethod('token');

export function PayApp() {
  return (
    <Providers>
      <Pay />
      <GlobalStyles />
    </Providers>
  );
}
