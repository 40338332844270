import { minFullHeightMobile } from '@m/styled/mixins';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  :root {
    background-color: ${({ theme }) => theme.colors.grey1};
    color: #000;
    box-sizing: border-box;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    min-height: 100%;
    flex: 1;
    flex-shrink: 0;

    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-drag: none;
    user-select: none;
    ${minFullHeightMobile}
  }

  body {
    font-feature-settings: normal;
    font-variant-numeric: normal;
    background-color: inherit;

    & *,
    & *::before,
    & *::after {
      -webkit-user-select: none;
      user-select: none;
    }
  }

  body > iframe {
    display: none !important;
  }

  input,
  textarea,
  select,
  button {
    appearance: none;
    all: initial;
    font: inherit;
    color: inherit;
    box-sizing: inherit;
  }
`;
